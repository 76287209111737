import React from 'react';
import { Link } from 'gatsby';

import Icon from '../custom-widgets/icon';

//Styles
import styles from "./wafd-built-partner.module.scss";

import WaFdBuiltLogosSVG from "../../images/third-party/wafd-built-logos.svg";

const WaFdBuiltPartner = () => {
    {/* Built Construction and WaFd Bank  */ }
    return (
        <section className="bg-blue-10 py-0">
            <div className="container py-4">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                        <img src={WaFdBuiltLogosSVG} alt="Built Construction and WaFd Bank logo" className={styles.logoPartner} />
                    </div>
                    <div className="d-none d-lg-block col-lg-1"></div>
                    <div className="col-12 col-md-6">
                        <h4 className="mb-0">
                            WaFd Bank has partnered with Built to streamline draws, inspections and more.{" "}
                            <Link
                                to="/built-construction-loan-management"
                                id="built-construction-cta"
                                className="text-decoration-none"
                            >
                                Learn how Built works
                                <Icon name="arrow-right" lib="fal" class="ml-2" />
                            </Link>
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WaFdBuiltPartner